import { selectTestaniaName } from 'redux/testania/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';

import { selectUser } from 'redux/user/selectors';
import { setAppLoader } from 'redux/uiEffects/actions';

import { DEFAULT_LTV_COEFFICIENT, LTV_COEFFICIENT_KEY } from 'constants/ltv';

import { initGTM } from 'services/Analytics/init';
import { initAmazonAnalytic, initHotjar } from 'services/Analytics';

import useSkipOnboarding from 'hooks/useSkipOnboarding';

import { setParamsToWindow } from 'helpers/window';
import { isAttributionFlow } from 'helpers/utils';
import { LS_KEYS } from 'helpers/localStorage';
import { setAnalyticData, setUrlParams, setUserPlatform } from './helpers';

function useAppInit() {
    const dispatch = useDispatch();

    useSkipOnboarding();

    const prevCountry = useRef();
    const prevAbTestName = useRef();

    const { country } = useSelector(selectUser);
    const abTestName = useSelector(selectTestaniaName);

    setParamsToWindow();

    const setNewPropsToRef = () => {
        prevCountry.current = country;
        prevAbTestName.current = abTestName;
    };

    useEffect(() => {
        const testaniaStatus = localStorage.getItem(LS_KEYS.testaniaResponseStatus);

        localStorage.setItem(LTV_COEFFICIENT_KEY, String(DEFAULT_LTV_COEFFICIENT));

        setNewPropsToRef();
        setAnalyticData({ country, abTestName });
        initGTM();
        setUrlParams(dispatch);
        setUserPlatform(dispatch);

        !isAttributionFlow() && initAmazonAnalytic();

        if (testaniaStatus || isAttributionFlow()) {
            dispatch(setAppLoader(false));
        }

        initHotjar();
    }, []);

    useEffect(() => {
        if (prevCountry.current !== country || prevAbTestName.current !== abTestName) {
            setAnalyticData({ country, abTestName });
            setNewPropsToRef();
        }
    }, [country, abTestName]);
}

export default useAppInit;
